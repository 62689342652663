<template>
  <v-row>
    <v-spacer />
    <v-col>
      <v-row class="my-4 text-h4 text-center" justify="center">{{ title }}</v-row>
      <v-row class="text-h5 text-center" justify="center">{{$t('message.under_construction')}}</v-row>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
export default {
  name: "ConstructionAlert",
  props: {
    title: String,
  },
};
</script>